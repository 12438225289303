'use client'
import { Image } from '@nextui-org/react'
import Link from 'next/link'
import React from 'react'

export default function ProductHuntBadge({
  className,
  id,
  alt,
  theme = 'light',
}: {
  className?: string
  id: string
  theme?: 'light' | 'dark'
  alt: string
}) {
  return (
    <Link
      className={className}
      href={'https://www.producthunt.com/posts/' + id}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image
        src={
          'https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=' +
          id +
          '&theme=' +
          theme
        }
        alt={alt}
        className="min-w-[200px] min-h-[54px] rounded-none"
        width={200}
        height={54}
      />
    </Link>
  )
}
