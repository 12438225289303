'use client'
import React from 'react'
import labelsIcons from './images/labelsIcons.jpg'
import Image from 'next/image'
import ProductHuntBadge from './ProductHuntBadge'
import { useEffect, useState } from 'react'
import {Spinner} from "@nextui-org/react";

export default function StatsDisplay({
  className = '',
  countTitle,
  kvCountStorageKey,
  isUserReviewsShow = false,
  producthuntId,
  producthuntAltImage,
}: {
  className?: string
  countTitle: string
  kvCountStorageKey: string
  isUserReviewsShow?: boolean
  producthuntId: string
  producthuntAltImage: string
}) {
  const [countNumber, setCountNumber] = useState(0)

  // Function to fetch the count from KV
  async function getCount() {
    const res = await fetch('/api/get-' + kvCountStorageKey, {
      // Revalidate every 5 minutes
      next: { revalidate: 300 },
    })
    const data = await res.json()
    setCountNumber(data)
  }

  // Fetch the count on first render
  useEffect(() => {
    getCount()
  }, [])

  return (
    <div
      className={
        'flex flex-col md:flex-row justify-center gap-3 md:gap-14 py-5 md:py-2 mt-5 text-start ' +
        className
      }
    >
      <div className="flex gap-3 md:gap-14 justify-center">
        <div>
          <div className="text-sm">Featured by:</div>
          <div>
            <ProductHuntBadge
              alt={producthuntAltImage}
              className=""
              id={producthuntId}
            />
          </div>
        </div>

        {!isUserReviewsShow ? (
          ''
        ) : (
          <div className='w-28'>
            <div className="text-sm">{countTitle}:</div>
            <div className="text-4xl font-medium mt-1">
              {countNumber===0?<Spinner color="default"/>:countNumber?.toLocaleString('en-US')}
            </div>
          </div>
        )}
      </div>

      {isUserReviewsShow ? (
        <div className="min-w-[145px]">
          <div className="text-sm">User reviews:</div>
          <div
            className="text-[#FFCC00] text-2xl leading-5 mt-1"
            data-content="★★★★★"
          >
            ★★★★★
          </div>
          <div className="text-small text-[#829195] text-nowrap">
            Yep, really 5 stars 😎
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="w-28">
            <div className="text-sm">{countTitle}:</div>
            <div className="text-4xl font-medium mt-1">
              {countNumber===0?<Spinner color="default"/>:countNumber?.toLocaleString('en-US')}
            </div>
          </div>
        </div>
      )}

      <div className="md:w-[566px] md:max-w-[566px] md:h-[33px] mt-4 md:mt-0">
        <div className="text-sm">Our users have been hired by:</div>
        <div>
          <Image
            className="w-full"
            alt={
              'Customers has been hired by these companies: Facebook, Apple, Google, Walmart, Costco, PWC, Amazon'
            }
            src={labelsIcons}
          />
        </div>
      </div>
    </div>
  )
}
